<template>
  <div class="relative">
    <div class="vx-navbar-wrapper" :class="classObj">
      <vs-navbar
        class="vx-navbar navbar-custom navbar-skelton"
        :color="navbarColorLocal"
        :class="textColor"
      >
        <!-- SM - OPEN SIDEBAR BUTTON -->
        <feather-icon
          class="sm:inline-flex xl:hidden cursor-pointer p-2"
          icon="MenuIcon"
          @click.stop="showSidebar"
        />

        <vs-spacer />

        <!-- <i18n /> -->

        <!-- <search-bar /> -->

        <!-- <notification-drop-down /> -->

        <profile-drop-down />
      </vs-navbar>
    </div>
  </div>
</template>

<script>
// import I18n                 from './components/I18n'
// import SearchBar            from './components/SearchBar'
// import NotificationDropDown from './components/NotificationDropDown'
import ProfileDropDown from './components/ProfileDropDown';

export default {
  name: 'the-navbar-vertical',
  props: {
    navbarColor: {
      type: String,
      default: '#fffdf0',
    },
  },
  components: {
    // I18n,
    // SearchBar,
    // NotificationDropDown,
    ProfileDropDown,
  },
  computed: {
    navbarColorLocal() {
      return '#fffdf0';
      // return this.$store.state.theme === 'dark' &&
      //   this.navbarColor === '#fffdf0'
      //   ? '#10163a'
      //   : this.navbarColor;
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
    textColor() {
      return {
        'text-white':
          (this.navbarColor !== '#10163a' &&
            this.$store.state.theme === 'dark') ||
          (this.navbarColor !== '#fffdf0' &&
            this.$store.state.theme !== 'dark'),
      };
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },

    // NAVBAR STYLE
    classObj() {
      if (this.verticalNavMenuWidth === 'default') return 'navbar-default';
      else if (this.verticalNavMenuWidth === 'reduced') return 'navbar-reduced';
      else if (this.verticalNavMenuWidth) return 'navbar-full';
      return '';
    },
  },
  methods: {
    showSidebar() {
      this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true);
    },
  },
};
</script>
