<template>
  <div v-if="activeUser.name" class="the-navbar__user-meta flex items-center">
    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">{{ activeUser.name }}</p>
      <small>{{ roleName }}</small>
    </div>

    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
      <div class="con-img ml-3">
        <img
          key="onlineImg"
          :src="activeUser.photo"
          alt="user-img"
          width="40"
          height="40"
          class="rounded-full shadow-md cursor-pointer block profile-img"
        />
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">
          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="goTo('profile')"
          >
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Profile</span>
          </li>
          <vs-divider class="m-1" />

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="logout"
          >
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Logout</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
import { getRoleName } from '@/enums/UserRole';

export default {
  computed: {
    activeUser() {
      return this.$store.state.AppActiveUser;
    },
    roleName() {
      return getRoleName(this.activeUser.role);
    },
  },
  methods: {
    goTo(routeName) {
      this.$router.push({ name: routeName }).catch(() => {});
    },
    async logout() {
      await localStorage.removeItem('accessToken');
      await localStorage.removeItem('userInfo');
      await localStorage.removeItem('tokenExpiry');
      await localStorage.removeItem('permissions');

      this.$router.push({ name: 'login' }).catch(() => {});
    },
  },
};
</script>

<style scoped>
.profile-img {
  object-fit: cover;
}
</style>
